<template>
  <Card class="w-full" shadow>
    <div class="mx-auto">
      <transition name="slideUp" mode="out-in">
        <div class="message h-full" key="message">
          <h4 class="font-bold md:text-xl text-xl">Enter OTP</h4>
          <div class="relative">
            <div class="mx-auto">
              <OtpInput
                class="mt-8"
                @on-change="handleOnChange"
                @on-complete="handleOnChange"
              />
            </div>
            <Spinner v-if="resendLoading" />
            <div class="mt-4 flex" v-else>
              <p
                class="underline text-sm text-brandPurple cursor-pointer"
                v-if="showResendButton"
                @click="resendOtp"
              >
                Resend OTP
              </p>
              <div class="ml-auto" v-else>
                <vue-countdown
                  :time="60 * 1000"
                  v-slot="{ minutes, seconds }"
                  @end="showResendButton = true"
                >
                  <span class="text-sm text-grey"
                    >{{ minutes }}:{{ seconds }}</span
                  >
                </vue-countdown>
              </div>
            </div>
          </div>
          <div class="mt-12 bg-lightGrey-1 p-2 flex items-center">
            <svg
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                fill="#0EA2F5"
              />
            </svg>
            <p class="text-sm">
              If you don’t see our email in your inbox, please check your spam folder
            </p>
          </div>
          <Button
            text="Authorize"
            class="mt-6"
            @click="sendOtp"
            :shadow="true"
            width="w-full"
            :loading="loading"
            :disabled="otp.length !== 6"
          />
        </div>
      </transition>
    </div>
  </Card>
</template>
<script>
  import Vue from "vue";
  import { mapActions, mapState } from "vuex";
  import { Input } from "@/UI/Input";
  import { Button, SecondaryButton } from "@/UI/Button";
  import { OtpInput } from "@/UI/Input";
  import VueCountdown from "@chenfengyuan/vue-countdown";
  Vue.component("vue-countdown", VueCountdown);

  export default {
    components: {
      Input,
      Button,
      SecondaryButton,
      Spinner: () => import("@/UI/Spinner"),
      OtpInput,
    },
    data() {
      return {
        otp: "",
        showMessage: true,
        loading: false,
        emailLoading: false,
        resendLoading: false,
        valid: {
          email: false,
        },
        otpValid: false,
        showResendButton: false,
      };
    },
    computed: {
      ...mapState({
        id: (state) => state.auth.password_id,
      }),
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
    },
    mounted() {
      this.handleDelayOtpButton();
    },
    methods: {
      ...mapActions("auth", [
        "forgotPassword",
        "setOtpId",
        "verifyPasswordResetOtp",
      ]),
      ...mapActions("notification", ["showAlert", "showToast"]),
      handleOnChange(value) {
        this.otp = value;
      },
      sendOtp() {
        this.loading = true;
        this.verifyPasswordResetOtp({
          passwordResetId: this.id,
          otp: this.otp,
        })
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      resendOtp() {
        this.resendLoading = true;
        this.$axios
          .put(`/volt/password-change/${this.id}/resend-otp`)
          .then((res) => {
            this.resendLoading = false;
            this.setOtpId(res.data.data.id);
            this.showToast({
              display: true,
              description: "A new OTP has been sent to your email",
              icon: "success",
            });
            this.showResendButton = false;
            this.handleDelayOtpButton();
          })
          .catch(() => {
            this.resendLoading = false;
          });
      },
      handleDelayOtpButton() {
        setTimeout(() => {
          this.showResendButton = true;
        }, 60000);
      },
    },
  };
</script>
<style scoped>
  @import "../../../assets/styles/animation.css";
  /* .register-container {
    min-height: 100vh;
  } */
  .register-container h3 {
    font-size: 35px;
  }
  .register-container p {
    line-height: 30.15px;
  }
  .container-register {
    max-width: 33.831rem;
  }
  .resend-otp {
    position: absolute;
    top: 0;
    right: 0;
  }
  @media (max-width: 768px) {
    .register-container {
      min-height: calc(100vh - 15rem);
      max-width: 100%;
    }
  }
</style>
