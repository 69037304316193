var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Card", { staticClass: "w-full", attrs: { shadow: "" } }, [
    _c(
      "div",
      { staticClass: "mx-auto" },
      [
        _c("transition", { attrs: { name: "slideUp", mode: "out-in" } }, [
          _c(
            "div",
            { key: "message", staticClass: "message h-full" },
            [
              _c("h4", { staticClass: "font-bold md:text-xl text-xl" }, [
                _vm._v("Enter OTP"),
              ]),
              _c(
                "div",
                { staticClass: "relative" },
                [
                  _c(
                    "div",
                    { staticClass: "mx-auto" },
                    [
                      _c("OtpInput", {
                        staticClass: "mt-8",
                        on: {
                          "on-change": _vm.handleOnChange,
                          "on-complete": _vm.handleOnChange,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.resendLoading
                    ? _c("Spinner")
                    : _c("div", { staticClass: "mt-4 flex" }, [
                        _vm.showResendButton
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "underline text-sm text-brandPurple cursor-pointer",
                                on: { click: _vm.resendOtp },
                              },
                              [_vm._v(" Resend OTP ")]
                            )
                          : _c(
                              "div",
                              { staticClass: "ml-auto" },
                              [
                                _c("vue-countdown", {
                                  attrs: { time: 60 * 1000 },
                                  on: {
                                    end: function ($event) {
                                      _vm.showResendButton = true
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var minutes = ref.minutes
                                        var seconds = ref.seconds
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-sm text-grey",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(minutes) +
                                                  ":" +
                                                  _vm._s(seconds)
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                      ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-12 bg-lightGrey-1 p-2 flex items-center" },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "20",
                        height: "20",
                        viewBox: "0 0 20 20",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z",
                          fill: "#0EA2F5",
                        },
                      }),
                    ]
                  ),
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v(
                      " If you don’t see our email in your inbox, please check your spam folder "
                    ),
                  ]),
                ]
              ),
              _c("Button", {
                staticClass: "mt-6",
                attrs: {
                  text: "Authorize",
                  shadow: true,
                  width: "w-full",
                  loading: _vm.loading,
                  disabled: _vm.otp.length !== 6,
                },
                on: { click: _vm.sendOtp },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }