<template>
  <ForgotPasswordOtp />
</template>
<script>
  import ForgotPasswordOtp from "@/components/Auth/OtpForgotPassword";
  export default {
    components: {
      ForgotPasswordOtp,
    },
  };
</script>
